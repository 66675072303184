"use client";

import { Button, ButtonProps } from "@/components/button";
import { Modal } from "@/components/modal";
import { VideoPlayer } from "@/components/video-player";

export const VideoButton = ({
  children,
  url,
  ...props
}: ButtonProps & { url: string }) => {
  return (
    <Modal
      trigger={({ setOpen }: { setOpen: (open: boolean) => void }) => (
        <Button onClick={() => setOpen(true)} {...props}>
          {children}
        </Button>
      )}
    >
      <VideoPlayer url={url} />
    </Modal>
  );
};
