import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const buttonVariants = cva(
  "inline-flex text-center items-center justify-center rounded ring-2 font-semibold transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-blue-600 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "text-white bg-blue-600 ring-blue-600 hover:bg-blue-700 hover:ring-blue-700",
        secondary:
          "text-white bg-transparent ring-white hover:text-black hover:bg-white",
        ghost:
          "text-white bg-black/40 ring-black/40 hover:bg-black/50 hover:ring-black/50",
        link: "text-inherit !h-auto !px-0 !py-0 ring-0 underline-offset-4 hover:underline",
      },
      size: {
        default: "h-auto px-4 py-2",
        sm: "h-auto px-3",
        lg: "h-auto px-8",
        // icon: "h-9 w-9",
      },
    },
    // compoundVariants: [
    //   {
    //     variant: "default",
    //     size: "sm",
    //     className: "grid-cols-2 sm:grid-cols-4 md:grid-cols-6",
    //   },
    // ],
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }))}
        {...props}
      >
        {children}
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
