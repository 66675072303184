"use client";

import ReactPlayer from "react-player";

export const VideoPlayer = ({ url }) => {
  return (
    <div className="relative w-full pb-[56.25%]">
      <ReactPlayer
        url={url}
        playing={true}
        width="100%"
        height="100%"
        style={{ position: `absolute`, top: 0, left: 0 }}
      />
    </div>
  );
};
