import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import {
  parseISO,
  differenceInHours,
  format,
  formatDistanceToNow,
} from "date-fns";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const isValidHttpUrl = (string: string) => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const scrollElemToCenter = (element: any, duration = 1000) => {
  let offset;
  const elementOffset = element.offsetTop;
  const elementHeight = element.offsetHeight;
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight;

  if (elementHeight < windowHeight) {
    offset = elementOffset - (windowHeight / 2 - elementHeight / 2);
  } else {
    offset = elementOffset;
  }

  window.scrollTo({
    top: offset,
    left: 0,
    behavior: "smooth",
  });

  return false;
};

export function getDateDistance(timestamp: string) {
  if (!timestamp) return null;

  const today = new Date();
  const ts = parseISO(timestamp);

  if (!ts) return null;

  const diff = differenceInHours(today, ts);

  if (diff >= 12) {
    return format(ts, "MMMM do, yyy");
  } else {
    return formatDistanceToNow(ts, {
      addSuffix: true,
    });
  }
}
